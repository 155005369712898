<template>
<div id="app">
  
  <div class="flex flex-col flex-grow w-screen max-w-full min-h-screen text-base font-eina">
    
    <Nav/>
    
      <div class="font-eina">
        <notifications position="top right"/>
        <div class="flex items-center justify-center spin-content" v-if="loading">
          <pulse-loader class="" :loading="loading"></pulse-loader>
        </div>
        <router-view/>
      </div>
    <Footer/>
    <!-- <span class="absolute sticky inset-x-0 bottom-0 ">
      <Cookies/>
    </span> -->
    <span class="absolute sticky inset-x-0 bottom-0 z-50">
      <Cookies/>
      <span class="md:hidden">
        <MobileNav/>
      </span>
      
      
    </span>
    
  </div>
      
</div>
</template>


<script>
import Nav from '@/components/Nav.vue'
import Cookies from '@/components/Cookies.vue'
import Footer from '@/components/Footer.vue'
import MobileNav from '@/components/MobileNav.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  name: 'Home',
  components: {
    Nav,
    Footer,
    PulseLoader,
    MobileNav,
    Cookies
  },
  computed: {
     loading() {
       return this.$store.getters.loading
     }
   },
   mounted() {
     this.$cookies.set('theme','default')
   }
}
</script>
<style lang="scss" scoped>
.hiddencss {
  display: block;
}

html, body {
  scroll-behavior: smooth;
}

@media (min-width: 640px) {
  .hiddencss {
    display: none;
}
}

.spin-content {
  background-color: #fff;
  padding: 30px;
  align-items: center;
}
</style>
